import React from "react"

import AboutLayout from "../components/layouts/aboutLayout"
import Chamber from "../images/chamber-building.jpg"

const ContactPage = () => (
  <AboutLayout pageTitle="Contact Us">
    <div css={{
      display: 'flex',
      flexDirection: `column`,
      '& .form': {
        paddingBottom: `2rem`,
        marginBottom: `2rem`,
        borderBottom: '1px dotted #000',
      },
      "@media (min-width: 1250px)": {
        flexDirection: `row`,
        '& .form': {
          width: '74%',
          borderRight: '1px dotted #000',
          borderBottom: `none`,
          marginBottom: `0`,
          paddingBottom: `0`,
        },
        '& .info': {
          width: '22%',
          paddingLeft: '3rem',
          paddingTop: `3rem`,
        },
      }  
    }}>
    <div className="form" css={{
        display: 'flex',
        alignItems: `center`,
        flexDirection: `column-reverse`,
        "@media (min-width: 1250px)": {
          flexDirection: `row`
        },
        "@media (min-width: 1300px)": {
          flexDirection: `column-reverse`,
        },
        "@media (min-width: 1580px)": {
          flexDirection: `row`
        },
    }}>
      <div css={{
        display: "inline-block",
        width: "90%",
        margin: `2rem 2rem 1rem 2rem`,
        "@media (min-width: 1250px)": {
          width: `50%`,
          marginBottom: `0`,
        },
        "@media (min-width: 1300px)": {
          width: "90%",
        },
        "@media (min-width: 1580px)": {
          width: `50%`,
          marginBottom: `0`,
        },
      }}>
        <img src={Chamber} css={{width: `100%`, border: "1px solid #000",}} />
      </div>
      <div css={{
        width: `80%`, 
        
        "@media (min-width: 1250px)": {
          width: `30%`,
          marginBottom: `0`,
          marginLeft: `3rem`,
        },
        "@media (min-width: 1300px)": {
          width: "80%",
        },
        "@media (min-width: 1580px)": {
          width: `30%`,
          marginLeft: `3rem`,
        },  
      }}>
        <h2 css={{fontSize: `2rem`}}>Send us a Message</h2>

        { /** THIS IS THE FORM START */}
        <form name="contact" method="POST" data-netlify="true"
        css={{
          'div': {
            margin: `1rem 0`,
          },
          'div  > * ': {
            display: 'block',
          },
          'span': {
            display: `inline-block`,
            fontSize: `0.75rem`,
            paddingLeft: '0rem',
            color: `red`,
          },
          'input, textarea': {
            width: `100%`,
            padding: `0.5rem 0.75rem`,
            backgroundColor: `#f1f2f7`,
            boxShadow: `0px 1px 4px -2px #383838`,
            border: `1px solid #abadb3`,
            outline: `none`,
            margin: `0.25rem 0`
          },
          'small': {
            display: `none`,
          }
        }}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div>
          <label for="fullName">Full Name: <span><sup>*</sup></span></label>
          <input id="fullName" type="text" name="fullName" required/>
          <small>Please enter your full name.</small>
        </div>

        <div>
          <label for="email">Email Address: <span><sup>*</sup></span></label>
          <input id="email" type="email" name="email" required/>
          <small>Please enter a valid email address.</small>
        </div>

        <div>
          <label for="phone">Phone Number: <span><sup>*</sup></span></label>
          <input id="phone" type="tel" name="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required/>
          <small>Format: 123-456-7890</small>
        </div>
        
        <div>
          <label for="message">Message: <span><sup>*</sup></span></label>
          <textarea id="message" name="message" rows="8" required></textarea>
          <small>A message is required.</small>
        </div>

        <p>
          <button type="submit" css={{
            display: "inline-block",
            marginTop: "0.75rem",
            backgroundColor: "rgb(9, 37, 86)",
            color: "#fff",
            textDecoration: "none",
            padding: "0.667rem",
            borderRadius: "2px",
            fontSize: `1rem`,
            outline: `none`,
            border: `none`,
          }}>Send Message</button>
        </p>
      </form> 
{/** THIS IS THE FORM END */}

    </div>
    </div>
    <div className="info">
    
      <h3 css={{fontSize: `1.5rem`, marginBottom: '2rem'}}>Contact Info</h3>
      <p>
        302 West Railroad Street<br />
        Claxton, Georgia 30417<br /><br />
      </p>
      <p>
        P: 912.739.1391<br />
        F: 912.739.3827<br /><br />
      </p>
      <p>
        Hours: M-F 8am-5pm
      </p>
      </div>
    
    </div>
  </AboutLayout>
)

export default ContactPage
